<template>
    <div class="StationArchives flex-col item-b" v-loading="showLoad" element-loading-text="拼命加载中">
        <!-- 查询控制 -->
        <div class="header">
            <div class="search-box">
                <el-input  class="custom-input" v-model="searchText" placeholder="请输入转运站名称"></el-input>
            </div>
            <el-button class="btn item-primary" type="primary" @click="searchBtn">查询</el-button>
            <el-button class="btn item-warning" type="warning" @click="addBtn">新增</el-button>
            <el-button class="btn item-info" type="info" @click="resetBtn">重置</el-button>
        </div>

        <!-- 表格内容 -->
        <div class="content">
            <el-table :data="dataList" stripe :header-cell-style="tableHeaderColor" :cell-style="rowClass">
                <el-table-column align="center" width="60" label="序号"  type="index"></el-table-column>
                <el-table-column align="center" label="转运站名称" prop="transferPointName" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="清运单位" prop="managementDeptName" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="地址" prop="location" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="箱数" prop="box" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="清运车辆" prop="vehicle" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="联系电话" prop="transferPhone" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="经纬度" prop="position" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="设备地址码" prop="deviceAddress" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="绑定公厕" prop="toiletName" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="操作" min-width="150px">
                    <template slot-scope="scope">
                        <div class="flex btnbox">
                            <el-button plain type="primary" size="small" @click="editBtn(scope)">编辑</el-button>
                            <el-button plain type="danger" size="small" @click="removeBtn(scope)">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
             <!-- 分页器 -->
            <div class="c-center page-control">
                <el-pagination
                    :total="total"
                    background
                    layout="total,prev, pager, next, jumper"
                    @current-change="pageChange"
                    :current-page.sync="curIndex"
                ></el-pagination>
            </div>
        </div>

        <!-- 新增转运站 -->
        <el-drawer
            custom-class="drawer-class"
            :visible.sync="show"
            :show-close="false"
            :withHeader="false"
            :wrapperClosable="false"
            @close="closeDrawer"
        >
            <div class="drawer-box flex-col">
                <div class="title">新增转运站</div>
                <div class="table auto">
                    <el-form
                        :model="addSTData"
                        size="small"
                        :rules="addSTDataRule"
                        label-position="left"
                        label-width="auto"
                        ref="addForm"
                        :hide-required-asterisk="true"
                    >
                        <el-form-item label="转运站名称" prop="stationName">
                            <el-input placeholder="请输入转运站名称" v-model="addSTData.stationName"></el-input>
                        </el-form-item>
                        <el-form-item label="清运单位">
                            <el-input placeholder="清运单位" v-model="addSTData.deptName" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="箱数">
                            <el-select v-model="addSTData.box" placeholder="请选择箱数" @change="changBox">
                                <el-option label="1" :value="1"></el-option>
                                <el-option label="2" :value="2"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item
                            v-for="(item, index) in addSTData.boxItem" :key="item.box"
                            :label="'设备地址码'+item.box" :prop="'boxItem.'+ index + '.deviceAddress'"
                            :rules="addSTDataRule.code"
                        >
                            <el-input placeholder="请输入设备地址码" v-model="item.deviceAddress" @blur="devicecode(item.deviceAddress)"></el-input>
                        </el-form-item>

                        <el-form-item label="清运车辆" prop="vehicle">
                            <el-input placeholder="请输入清运车辆" v-model="addSTData.vehicle"></el-input>
                        </el-form-item>
                        <el-form-item label="绑定公厕名称" prop="toiletId">
                            <el-select v-model="addSTData.toiletId" placeholder="请选择绑定公厕" @change="changeToile">
                                <el-option v-for="item in toiletList" :label="item.toiletName" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="地址" prop="addr">
                            <el-input placeholder="请输入地址" v-model="addSTData.addr"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="phone">
                            <el-input placeholder="请输入联系电话" v-model="addSTData.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="经纬度" prop="position">
                            <el-input
                                placeholder="请选择经纬度"
                                v-model="addSTData.position"
                                @focus="showMap = true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="电子围栏" prop="zone" :show-message="!addSTData.zone">
                            <el-button class="submit-primary" type="primary" size="small" @click="addZone">{{ addSTData.zone ? '添加完成' : '添加围栏'}}</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="c-center btn">
                    <el-button class="submit-primary" type="primary " @click="saveAdd">保存</el-button>
                    <el-button class='submit-info' type="info " @click="cancelAdd">取消</el-button>
                </div>
            </div>
        </el-drawer>

        <!-- 编辑转运站 -->
        <el-drawer
            custom-class="drawer-class"
            :visible.sync="showEdit"
            :show-close="false"
            :withHeader="false"
            :wrapperClosable="false"
            @close="closeDrawer"
        >
            <div class="drawer-box flex-col">
                <div class="title">编辑转运站</div>
                <div class="table auto">
                    <el-form
                        :model="editSTData"
                        size="small"
                        :rules="addSTDataRule"
                        label-position="left"
                        label-width="auto"
                        ref="editForm"
                        :hide-required-asterisk="true"
                    >
                    <template v-if="boxShow">
                        <el-form-item label="箱数">
                            <el-select v-model="editSTData.box" placeholder="请选择箱数" @change="changBoxs" disabled>
                                <el-option label="箱数1" :value="1"></el-option>
                                <el-option label="箱数2" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            v-for="(item) in editSTData.boxItem" :key="item.box"
                            :label="'设备地址码'+item.box"
                        >
                            <el-input placeholder="请输入设备地址码" v-model="item.deviceAddress" disabled></el-input>
                        </el-form-item>
                    </template>
                    <template v-else>
                        <el-form-item label="箱数">
                            <el-select v-model="editSTData.box" placeholder="请选择箱数" @change="changBoxs" >
                                <el-option label="箱数1" :value="1" ></el-option>
                                <el-option label="箱数2" :value="2" ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            v-for="(item,index) in editSTData.boxItem" :key="item.box"
                            :label="'设备地址码'+item.box" :prop="'boxItem.'+ index + '.deviceAddress'"  :rules="{required: true, message: '请输入设备地址码',trigger: 'blur'}"
                        >
                            <el-input placeholder="请输入设备地址码" v-model="item.deviceAddress" :disabled='item.show'></el-input>
                        </el-form-item>
                    </template>
                        <el-form-item label="转运站名称" prop="stationName">
                            <el-input placeholder="请输入转运站名称" v-model="editSTData.stationName"></el-input>
                        </el-form-item>
                        <el-form-item label="清运单位">
                            <el-input placeholder="清运单位" v-model="editSTData.deptName" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="清运车辆" prop="vehicle">
                            <el-input placeholder="请输入清运车辆" v-model="editSTData.vehicle"></el-input>
                        </el-form-item>
                        <el-form-item label="绑定公厕名称" >
                            <el-select v-model="editSTData.toiletId" placeholder="请选择绑定公厕">
                                <el-option v-for="item in toiletList" :label="item.toiletName" :value="item.id" :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="地址" prop="addr">
                            <el-input placeholder="请输入地址" v-model="editSTData.addr"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="phone">
                            <el-input placeholder="请输入联系电话" v-model="editSTData.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="经纬度" prop="position">
                            <el-input
                                placeholder="请选择经纬度"
                                v-model="editSTData.position"
                                @focus="showMap = true"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="电子围栏" prop="zone">
                            <el-button type="primary" class="submit-primary"  size="small" @click="editZone">编辑围栏</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="c-center btn">
                    <el-button class="submit-primary" type="primary " @click="saveEdit">保 存</el-button>
                    <el-button class="submit-info" type="info " @click="cancelEdit">取消</el-button>
                </div>
            </div>
        </el-drawer>

        <!-- 选择地图经纬度 -->
        <el-dialog :visible.sync="showMap" @opened="openMap" :center="true" title="点击地图选择经纬度">
            <div id="selectPos"></div>
        </el-dialog>

        <!-- 选择电子围栏 -->
        <el-dialog :visible.sync="showMapZone" @opened="openMapZone" @close="closeMapZone" :center="true">
            <div id="selectZone"></div>
            <template slot="footer">
                <el-button class="submit-info" type="info" @click="showMapZone = false">取消</el-button>
                <el-button class="submit-primary" type="primary" @click="saveZone">保存</el-button>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import axios from "../request";
import { Message } from "element-ui"

import {tableHeaderColor,rowClass} from '@/utils/table'
export default {
    name: "StationArchives",
    data() {
        return {
            map: null,
            zmap: null,
            show: false,
            showEdit: false,
            showMap: false,
            showMapZone: false,
            showLoad: true,
            boxShow:false,
            curIndex: 1,
            searchText: "",
            total: 0,
            dataList: [],
            dept: null,
            toiletList: [], //公厕列表
            // 增加转运站的数据字段
            addSTData: {
                stationName: "", //转运站名称
                deptName: "", //清运单位
                box: 1,  //箱体，默认1个，最多2个
                boxItem: [{box: 1, deviceAddress: ""}],  //设备码
                vehicle: "",  //清运车辆
                toiletId: "",  //绑定公厕id
                addr: "",  //地址
                position: "", //经纬度
                phone: "", //联系电话
                zone: "", //电子围栏
            },
            editSTData: {
                stationName: "", //转运站名称
                deptName: "", //清运单位
                box: 1,  //箱体，默认1个，最多2个
                boxItem: [{box: 1, deviceAddress: ""}],  //设备码
                vehicle: "",  //清运车辆
                toiletId: "",  //绑定公厕id
                addr: "",  //地址
                position: "", //经纬度
                phone: "", //联系电话
                zone: "", //电子围栏
            },
            // 增加转运站的校验规则
            addSTDataRule: {
                stationName: [
                    { required: true, message: "请输入转运站名称" },
                    { max: 20, message: "最多20个字符" },
                ],
                vehicle: [
                    { required: true, message: "请输入清运车辆" },
                ],
                toiletId: [
                    { required: false, message: "请选择绑定公厕" },
                ],
                phone: [
                    { required: true, message: "请输入联系电话" },
                    { tyep: "regexp", pattern: /^1[0-9]{10}$/, message: "请输入正确的联系电话" },
                ],
                position: [{ required: true, message: "请选择经纬度" }],
                addr: [
                    { required: true, message: "请输入地址" },
                    { max: 50, message: "最多50个字符" },
                ],
                code: [{ required: true, message: "请输入设备地址码" }],
                zone: [{ required: true, message: "请添加电子围栏" }]
            },
            overlayZone: null,
            overlayZoneData: "",
            curPosition: "",
            zoneType: 0,    //1编辑；0新增
            editLay: null,
            drawingManager: null
        };
    },
    methods: {
        tableHeaderColor({ row, rowIndex }) {
        return tableHeaderColor()
        },
        rowClass({ row, rowIndex }) {
            return rowClass()
        },
        addZone(){
            if(!this.addSTData.position){
                return Message({
                    message: "请先选择经纬度",
                    showClose: true,
                    type: "warning",
                });
            }else{
                this.curPosition = this.addSTData.position
                this.showMapZone = true
                this.zoneType = 0
            }
        },
        editZone(){
            if(!this.editSTData.position){
                return Message({
                    message: "请先选择经纬度",
                    showClose: true,
                    type: "warning",
                });
            }else{
                this.curPosition = this.editSTData.position
                this.showMapZone = true
                this.zoneType = 1
            }
        },
        saveZone(){
            if(this.overlayZoneData){
                
                this.showMapZone = false
                if(this.zoneType){
                    this.editSTData.zone = this.overlayZoneData
                }else{
                    this.addSTData.zone = this.overlayZoneData
                }
            }else{
                Message({
                    message: "请添加电子围栏",
                    showClose: true,
                    type: "warning",
                })
            }
        },
        cancelAdd() {
            this.$refs.addForm.resetFields();
            this.show = false;
        },
        cancelEdit(){
            this.showEdit = false;
        },
        // 验证设备码是否重复
        async devicecode(address){
            console.log(address)
        
            let data = await axios.get("/transferPointDetail/addressRepeatJudgment", {address});
            console.log(data)
            if (!data) {
                this.$message.error('地址码有重复');
            }


        },
        // 提交新增
        saveAdd() {
            this.$refs.addForm.validate(async valid => {
                if(valid){
                    const params = {
                        toiletId: this.addSTData.toiletId,
                        transferPointName: this.addSTData.stationName,
                        longitude: this.addSTData.position.split(",")[0],
                        latitude: this.addSTData.position.split(",")[1],
                        location: this.addSTData.addr,
                        vehicle: this.addSTData.vehicle,
                        transferPhone: this.addSTData.phone,
                        details: this.addSTData.boxItem,
                        electronicFence: this.addSTData.zone,
                    }
                    let data = await axios.post("/transferPoint/add", params);
                    if (data === null) {
                        this.cancelAdd();
                        this.resetBtn();
                        this.$router.go(0) 
                        this.$message({
                            message: "添加成功！",
                            type: "success",
                        });
                    }
                }
            });
        },
        changeToile(val){
                let phones=this.toiletList.find(res=>{
                if(val==res.id){
                    return res
                }
            })
            this.addSTData.addr=phones.location
            this.addSTData.position=`${phones.longitude},${phones.latitude}`
            //this.phone=phones.phone
        },
        // 提交编辑
        saveEdit(){
            this.$refs.editForm.validate(async valid => {
                if(valid){
                    const params = {
                        id: this.editSTData.id,
                        toiletId: this.editSTData.toiletId,
                        transferPointName: this.editSTData.stationName,
                        longitude: this.editSTData.position.split(",")[0],
                        latitude: this.editSTData.position.split(",")[1],
                        location: this.editSTData.addr,
                        vehicle: this.editSTData.vehicle,
                        transferPhone: this.editSTData.phone,
                        details: this.editSTData.boxItem,
                        electronicFence: this.editSTData.zone
                    }
                   try{
                        let data = await axios.post("/transferPoint/update", params)
                    if (data === null) {
                        this.cancelEdit();
                        this.resetBtn();
                        this.$message({
                            message: "编辑成功！",
                            type: "success",
                        });
                    }
                   }catch(data){
                       console.log(data)
                   }
                }
            });
        },
        async pageChange(index = 1) {
            this.showLoad = true;
            let data = await axios.get(`/transferPoint/page/${index}/10`, { params: { transferPointName: this.searchText } });
            if (data) {
                this.total = data.total;
                console.log(data)

                this.dataList = data.list.map(item => {
                    item.position = item.longitude + "," + item.latitude
                    // 处理箱体，设备码
                    let box = [], code = [];
                    item.detailSimpleVos.forEach(i => {
                        box.push(i.box)
                        code.push(i.deviceAddress)
                    })
                    item.box = box.join(",")
                    item.deviceAddress = code.join(",")
                    return item;
                });
                console.log(this.dataList)
                this.showLoad = false;
            }
        },
        searchBtn() {
            if (this.searchText === "") return;
            this.pageChange();
        },
        resetBtn() {
            this.searchText = "";
            this.curIndex = 1;
            this.pageChange();
        },
        openMap() {
            if (!this.map) {
                const that = this;
                const point = new BMap.Point(117.584723, 37.650997); // 创建点坐标
                this.map = new window.BMap.Map("selectPos");
                this.map.centerAndZoom(point, 13);
                this.map.enableScrollWheelZoom(true);
                this.map.addEventListener("click", function(e) {
                    that.addSTData.longitude = e.point.lng;
                    that.addSTData.latitude = e.point.lat;
                    that.addSTData.position = e.point.lng + "," + e.point.lat;
                    that.editSTData.position = e.point.lng + "," + e.point.lat;
                    that.showMap = false;
                });
                 var bdary = new BMap.Boundary();
                    bdary.get('东营区', function (rs) {
                        //获取行政区域
                    // Thit.map.clearOverlays(); //清除地图覆盖物
                        var count = rs.boundaries.length; //行政区域的点有多少个
                        for (var i = 0; i < count; i++) {
                        var ply = new BMap.Polygon(rs.boundaries[i], {
                            strokeWeight: 2,
                            fillColor: "#8BFFCC", //设置多边形填充颜色
                            strokeColor: "#259E50",
                            fillOpacity: 0.2 
                        }); //建立多边形覆盖物

                        that.map.addOverlay(ply); //添加覆盖物
                        that.map.setViewport(ply.getPath()); //调整视野
                        }
                    });
            }
        },
        openMapZone(){
            let pos = this.curPosition.split(",")
            let point = new window.BMap.Point(pos[0], pos[1]);
            if (!this.zmap) {
                this.zmap = new window.BMap.Map("selectZone", { enableMapClick: false });
                this.zmap.centerAndZoom(point, 13);
                this.zmap.enableScrollWheelZoom(true);
                const drawingManager = this.drawingManager = new BMapLib.DrawingManager(this.zmap, {
                    isOpen: false,              //是否开启绘制模式
                    enableDrawingTool: true,    //是否显示工具栏
                    drawingToolOptions: {
                        anchor: BMAP_ANCHOR_TOP_RIGHT,  //位置
                        offset: new BMap.Size(5, 5),    //偏离值
                        drawingModes : [BMAP_DRAWING_RECTANGLE]
                    },
                    rectangleOptions: {         //矩形的样式
                        strokeColor:"#409EFF",      //边线颜色
                        fillColor:"#409EFF",        //填充颜色
                        strokeWeight: 2,        //边线的宽度，以像素为单位。
                        strokeOpacity: 0.8,	    //边线透明度，取值范围0 - 1。
                        fillOpacity: 0.6,       //填充的透明度，取值范围0 - 1。
                        strokeStyle: 'solid'    //边线的样式，solid或dashed。
                    }
                });
                drawingManager.addEventListener('overlaycomplete', e => {
                    if(this.overlayZone){
                        this.zmap.removeOverlay(this.overlayZone)
                    }
                    if(this.editLay){
                        this.zmap.removeOverlay(this.editLay)
                    }
                    this.overlayZone = e.overlay
                    console.log(e.overlay);
                    this.overlayZoneData = JSON.stringify(e.overlay.Ao)
                });
            }
            this.zmap.setCenter(point)
            this.zmap.clearOverlays()
            // 新增电子围栏地图时，如果已经添加过，则绘制出来
            if(this.zoneType === 1 && this.editSTData.zone){
                this.overlayZoneData = this.editSTData.zone
                let zone = JSON.parse(this.editSTData.zone)
                let points = []
                zone.forEach(item => {
                    points.push(new BMap.Point(item.lng, item.lat))
                })
                this.editLay = new BMap.Polygon(points, {
                    strokeColor:"#409EFF",
                    fillColor:"#409EFF",
                    strokeWeight: 2,
                    strokeOpacity: 0.8,
                    fillOpacity: 0.6,
                    strokeStyle: 'solid'
                })
                this.zmap.addOverlay(this.editLay)
            }
            // 编辑地图围栏时，绘制
            if(this.zoneType === 0 && this.addSTData.zone){
                let zone = JSON.parse(this.addSTData.zone)
                let points = []
                zone.forEach(item => {
                    points.push(new BMap.Point(item.lng, item.lat))
                })
                this.overlayZone = new BMap.Polygon(points, {
                    strokeColor:"#409EFF",
                    fillColor:"#409EFF",
                    strokeWeight: 2,
                    strokeOpacity: 0.8,
                    fillOpacity: 0.6,
                    strokeStyle: 'solid'
                })
                this.zmap.addOverlay(this.overlayZone)
            }
        },
        closeMapZone(){
            // 关闭电子围栏地图时，设置工具栏为拖动，并清除地图上所有覆盖物
            this.drawingManager._drawingTool.setStyleByDrawingMode("hander")
            this.drawingManager._drawingTool._bindEventByDraingMode("hander")
            this.zmap.clearOverlays()
        },
        closeDrawer(){
            this.overlayZoneData = null
        },
        removeBtn({row}) {
            this.$confirm("此操作将永久删除此转运站, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(async () => {
                let data = await axios.post(`/transferPoint/deleteById/${row.id}`)
                if(data === null){
                    this.resetBtn()
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                    this.$router.go(0)
                }
            })
            .catch(() => {});
        },
        editBtn({row}) {
            this.getData(row.toiletId)
            this.showEdit = true;
            this.editSTData.stationName = row.transferPointName
            this.editSTData.deptName = row.managementDeptName
            this.editSTData.box = row.detailSimpleVos.length
            this.editSTData.boxItem = row.detailSimpleVos
            this.editSTData.vehicle = row.vehicle
            this.editSTData.toiletId = row.toiletId
            this.editSTData.addr = row.location
            this.editSTData.position = row.position
            this.editSTData.phone = row.transferPhone
            this.editSTData.zone = row.electronicFence
            this.editSTData.id = row.id
            if(row.box=='1,2'){
                this.boxShow = true
            }else if(row.box=='1'){
                this.boxShow = false
                this.editSTData.boxItem[0].show=true
            }
        },
        addBtn(){
            this.getData()
            this.show = true;
        },
        getData(toiletId='0'){
            if (!this.dept) {
                axios.get(`/sysDict/findByCode`, { params: { code: "management_dept" } }).then((res)=>{
                        if (res) {
                    this.dept = res;
                    this.addSTData.deptName = res.dataValue;
                  }
                })
                
            }
                if(toiletId==null){
                    toiletId='0'
                     axios.get(`/toilet/findNotBound?toiletId=${toiletId}`).then(res=>{
                        if(res){
                                this.toiletList = res
                            }
                    })
                    
                }else{
                    axios.get(`/toilet/findNotBound?toiletId=${toiletId}`).then(res=>{
                        if(res){
                                this.toiletList = res
                            }
                    })
                }
        },
        changBox(e){
            console.log(this.addSTData)
            if(e === 2){
                this.addSTData.boxItem.push({box:'',deviceAddress:''})
            }
            if(e === 1){
                this.addSTData.boxItem.pop()
            }
        },
        changBoxs(e){
            if(e === 2){
                this.editSTData.boxItem.push({box:'2',deviceAddress:'',show:false})
            }
            if(e === 1){
                this.editSTData.boxItem.pop()
            }
        },
    },
    
    created() {
        this.pageChange();
    },
    
};
</script>

<style scoped lang="scss">
.StationArchives {
    flex: auto;
    height: 100%;
    box-sizing: border-box;
    & ::v-deep .el-drawer__body {
        overflow: auto;
    }
}
.header {
    flex: none;
    display: flex;
    padding: 20px;
    .search-box {
        width: 12rem;
        margin-right: 10px;
    }
    .btn{
        font-weight: 500;
        border: none;
    }
}
.content {
    overflow: auto;
    flex: auto;
    width: calc(100% - 8px);
}
.page-control {
   position: relative;
    top:5%;
}
.drawer-box {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;

    .title {
        padding: 20px;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        border-bottom: solid 1px #dadada;
    }
    .table {
        padding: 20px;
        overflow: auto;
    }
    .btn {
        padding: 30px 0;
    }
}
.drawer-box ::v-deep .el-select {
    width: 100%;
}
.drawer-class ::v-deep .el-drawer__body {
    overflow: auto;
}
.btnbox {
    margin: 0 auto;
    width: 132px;
}
#selectPos, #selectZone {
    width: 100%;
    height: 400px;
}
.el-button--danger.is-plain{
    background: white;
}
.el-button--danger.is-plain:focus{
    background: white;
    color: #F56C6C;
}
.el-button--danger.is-plain:hover{
    background: #F56C6C;
    border-color: #F56C6C;
    color: #FFF;
}
.el-button--danger.is-plain:active{
    background: white;
    color: #F56C6C;
}
.el-button--primary.is-plain{
    background: white;
}
.el-button--primary.is-plain:focus{
        color: #409EFF;
    background: white;
}
.el-button--primary.is-plain:hover{
        color: #fff;
    background: #409EFF;
    border-color: #409EFF;
}
.el-button--primary.is-plain:active{
        color: #409EFF;
    background: white;
    //border-color: #b3d8ff;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color:#3B7FFF ;
}
@media screen and(max-height: 1080px ){
 ::v-deep .page-control{
        text-align: center;
        margin: 4% 0px;
    }

}
@media screen and(max-height: 970px ){
 ::v-deep .page-control{
        text-align: center;
        margin: 1% 0px;
    }
}
</style>